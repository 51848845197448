import React from "react";

import { Breadcrumb, Container, AccessContents } from "../../components";
import Seo from "../../components/Seo";

const AccessPage = () => {
  return (
    <Container>
      <Seo
        title="東京オフィスへの行き方"
        description="TKCホールディング東京オフィスの地図・行き方をご確認いただけます。"
      />
      <AccessContents />
      <Breadcrumb
        currentPage="Access"
        currentSlug="/company/access"
        parentPage="Company"
        parentSlug="/company"
      />
    </Container>
  );
};

export default AccessPage;
